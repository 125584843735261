module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"387510143227949"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sensatia Botanicals","short_name":"Sensatia","start_url":"/","background_color":"#ffff","theme_color":"#9DBFAE","display":"standalone","icon":"src/images/favicon/favicon-192x192.jpg","icons":[{"src":"src/images/favicon/favicon-48x48.jpg","sizes":"48x48","type":"image/jpg"},{"src":"src/images/favicon/favicon-96x96.jpg","sizes":"96x96","type":"image/jpg"},{"src":"src/images/favicon/favicon-144x144.jpg","sizes":"144x144","type":"image/jpg"},{"src":"src/images/favicon/favicon-192x192.jpg","sizes":"192x192","type":"image/jpg"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f7b95f95d5e9ef8eeaf15ed692ae93d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/app/sign-up/","/app/jp/sign-up/","/app/id/sign-up/","/app/login/","/app/jp/login/","/app/id/login/"],"crumbLabelUpdates":[{"pathname":"/eu","crumbLabel":"Home"},{"pathname":"/id","crumbLabel":"Home"},{"pathname":"/jp","crumbLabel":"Home"},{"pathname":"/en","crumbLabel":"Home"}],"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-identity-gotrue/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://sensatia.netlify.app"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-W93K2X5","G-Q8LPMEQG95"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/app/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
