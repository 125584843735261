exports.components = {
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-b-2-bcustomerform-1-contact-details-js": () => import("./../../../src/pages/b2bcustomerform/1ContactDetails.js" /* webpackChunkName: "component---src-pages-b-2-bcustomerform-1-contact-details-js" */),
  "component---src-pages-b-2-bcustomerform-2-delivery-address-js": () => import("./../../../src/pages/b2bcustomerform/2DeliveryAddress.js" /* webpackChunkName: "component---src-pages-b-2-bcustomerform-2-delivery-address-js" */),
  "component---src-pages-b-2-bcustomerform-3-company-billing-details-js": () => import("./../../../src/pages/b2bcustomerform/3CompanyBillingDetails.js" /* webpackChunkName: "component---src-pages-b-2-bcustomerform-3-company-billing-details-js" */),
  "component---src-pages-b-2-bcustomerform-4-individual-billing-details-js": () => import("./../../../src/pages/b2bcustomerform/4IndividualBillingDetails.js" /* webpackChunkName: "component---src-pages-b-2-bcustomerform-4-individual-billing-details-js" */),
  "component---src-pages-b-2-bcustomerform-index-js": () => import("./../../../src/pages/b2bcustomerform/index.js" /* webpackChunkName: "component---src-pages-b-2-bcustomerform-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-amenities-js": () => import("./../../../src/templates/amenities.js" /* webpackChunkName: "component---src-templates-amenities-js" */),
  "component---src-templates-cart-js": () => import("./../../../src/templates/cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-checkout-js": () => import("./../../../src/templates/checkout.js" /* webpackChunkName: "component---src-templates-checkout-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-item-js": () => import("./../../../src/templates/item.js" /* webpackChunkName: "component---src-templates-item-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-login-js": () => import("./../../../src/templates/login.js" /* webpackChunkName: "component---src-templates-login-js" */),
  "component---src-templates-marketplace-js": () => import("./../../../src/templates/marketplace.js" /* webpackChunkName: "component---src-templates-marketplace-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/newsSingle.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-sign-up-js": () => import("./../../../src/templates/sign-up.js" /* webpackChunkName: "component---src-templates-sign-up-js" */),
  "component---src-templates-store-locator-js": () => import("./../../../src/templates/storeLocator.js" /* webpackChunkName: "component---src-templates-store-locator-js" */),
  "component---src-templates-store-locator-region-js": () => import("./../../../src/templates/storeLocatorRegion.js" /* webpackChunkName: "component---src-templates-store-locator-region-js" */),
  "component---src-templates-store-locator-shop-js": () => import("./../../../src/templates/storeLocatorShop.js" /* webpackChunkName: "component---src-templates-store-locator-shop-js" */),
  "component---src-templates-success-js": () => import("./../../../src/templates/success.js" /* webpackChunkName: "component---src-templates-success-js" */),
  "component---src-templates-terms-condition-js": () => import("./../../../src/templates/termsCondition.js" /* webpackChunkName: "component---src-templates-terms-condition-js" */)
}

